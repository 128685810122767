.flags {
    width: 30px;
    height: 16px;;
}
.selectedFlag {
    border: 2px solid black;
}
.navbar {
    display: flex;
    background-color: #00B894;
    color: white;
    align-items: center;
    border-radius: 10px;
}
.selectedMenu {
    color: #4D4D4D !important;
}
.logo-container {
    margin-left: 10px;
    text-align: left;
    font-size: larger;
    border: 1px solid transparent;
    padding: 10px 15px 10px 15px;
    margin: 5px 0 5px 10px;
}
.logo {
    width: 150px;    
}
.link-lang-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.link-ul {
    display: flex;
}
.link-ul li {
    margin-right: 50px;
    list-style: none;
    text-decoration: none;
}
.link-ul li:hover {
    cursor: pointer;
}
.link-ul li a {
    color: white;
    text-decoration: none;
    font-weight: 500;
}
.link-ul li a:hover {
    color: rgb(243, 237, 237);
}
.hamburger-menu-icon {
    display: none;
}
.hamburger-menu {
    display: none;
}
.hamburger-menu li {
    list-style: none;
    text-decoration: none;
    font-size: 0.8rem;
}
.hamburger-menu a {
    text-decoration: none;
    color: #4D4D4D;
    font-size: x-large;
    font-weight: 500;
}
@media screen and (max-width: 800px) {
    .hamburger-menu-icon {
        display: block;
        width: 50px;
        margin-right: 4px;
    }
    .hamburger-menu-icon:hover {
        cursor: pointer;
    }
    .hamburger-menu {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 170px;
        width: 150px;
        padding: 10px;
    }
    .classic-menu {
        display: none;
    }    
    .link-ul li {
        margin-right: 5px;
    }
}
@media screen and (max-width: 500px) {
    .navbar {
      border-radius: 0;
    }
    .hamburger-menu {
        top: 120px;
    }
}
/* @media screen and (max-width: 365px) {
    .logo-container {
      font-size: medium;
    }  
} */
