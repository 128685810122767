.main-content {
    display: flex;
    flex-direction: row;
    margin: 10px 0 10px 0;
    border: 1px solid #85C1E9;
    border-radius: 10px;
}
.main-section {
    width: 100%;
    color: #4D4D4D;
    box-sizing: border-box;
}
.main-section-img {
    display: flex;
    align-items: center;
}
.main-section-text {
    padding: 20px;
    text-align: left;
}
.main-image {
    width: 100%;
    border-radius: 10px;
    display: block;
}
.buttonCta {
    background-color: #00B894;
    color: white;
    padding: 20px;
    border-radius: 15px;
    font-weight: bolder;
    border: none;
    box-shadow: 1px 1px black;
}
.buttonCta:hover {
    cursor: pointer;
    color: white;
    background-color: #007BFF;
    box-shadow: none;
}
@media screen and (max-width: 1320px) {
    .main-content {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 500px) {
    .main-content {
        border: none;
        border-radius: 0;
    }  
  }