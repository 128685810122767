.curriculum {
    color: #4D4D4D;
    text-align: left;
    padding: 20px;
    margin: 10px 0 10px 0;
    border: 1px solid #85C1E9;
    border-radius: 10px;
    font-size: medium;    
}
.curriculum-header {
    display: flex;
}
.curriculum-header div {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}