.portfolio-container {
    color: #4D4D4D;
    text-align: left;
    padding: 20px;
    margin: 10px 0 10px 0;
    border: 1px solid #85C1E9;
    border-radius: 10px;
    font-size: medium;    
}
.portfolio {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.portfolio-images-container:hover {
    cursor: pointer;
}
.portfolio-images {
    width: 100%;
}
.chapters-container {
    border: 1px solid #85C1E9;
    padding: 5px;
    text-align: center;
    margin: 5px;
    border-radius: 10px;
}
.chapters-container:hover {
    cursor: pointer;
}
@media screen and (max-width: 800px) {
    .portfolio {
        grid-template-columns: auto auto;
    }
}