.contatti {
    margin: 10px 0 10px 0;
    border: 1px solid #85C1E9;
    border-radius: 10px;
    color: #4D4D4D;
    padding: 20px;
    text-align: left;
}
.social-icon {
    margin-right: 10px;
    transform: scale(1.5);
}